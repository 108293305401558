import React, { useContext } from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/footer/index";
import {
    DocumentacionButton,
    DocumentacionCouples,
    DocumentacionImage,
    DocumentacionLinks,
    DocumentacionTexts,
    DocumentacionWrapper,
    HomeWrapper,
    NavbarWrapper,
    ButtonsWrapper,
    LinkWithoutDecoration
} from "./style";
import {
    Caption,
    Title,
    Paragraph,
    Title2,
    Label,
} from '../style/globalstyle';
import ArrowSvg from '../assets/images/arrowsvg.png';
import Documento from '../assets/images/documento.png';
import Logo from '../assets/images/logowhite.png';
import { Fade } from "react-awesome-reveal";
import { Text, LanguageContext } from '../../containers/Language';


const Documentacion = () => {
    const { userLanguage, dictionary } = useContext(LanguageContext);
    return (
        <HomeWrapper>
            <Navbar logo={Logo} documentation={'#2D0060'} svgcolor={'white'} selectcolor={'white'} burgerColor={'white'} burgerLinksColor={'black'} burgerLinesColor={'white'} />
            <DocumentacionWrapper>
                <DocumentacionTexts>
                    <Label><Text tid={"documentacionTab"} /></Label>
                    <Title2><Text tid={"documentacionTitle"} /></Title2>
                    <ButtonsWrapper>
                    <LinkWithoutDecoration href="https://github.com/ssi-quarkid/WhitePaper" target="_blank"><DocumentacionButton><div><Text tid={"documentacionButton"} /></div><img src={ArrowSvg} /></DocumentacionButton></LinkWithoutDecoration>                        {/* Ahora mismo no hay whitepaper en ingles porque se cayo, por ende les pongo el mismo link que el de español y despues poder reemplazarlo. */}
                    <LinkWithoutDecoration href={userLanguage === "es" ? "https://docs.quarkid.org/" : "https://docs.quarkid.org/"} target="_blank"><DocumentacionButton><div><Text tid={"leeDocumentacionButton"} /></div><img src={ArrowSvg} /></DocumentacionButton></LinkWithoutDecoration>
                    <LinkWithoutDecoration href="https://explorer.quarkid.org" target="_blank"><DocumentacionButton><div><Text tid={"explorerDocumentacionButton"} /></div><img src={ArrowSvg} /></DocumentacionButton></LinkWithoutDecoration>
                    </ButtonsWrapper>
                </DocumentacionTexts>
                <DocumentacionLinks>
                    <Label><Text tid={"documentacionLinksTitle"} /></Label>
                    <DocumentacionCouples>
                        <a href="https://www.w3.org/TR/did-core/" target="_blank">W3c</a>
                        <Paragraph>Decentralized Identifiers (DIDs)</Paragraph>
                    </DocumentacionCouples>
                    <DocumentacionCouples>
                        <a href="https://trustoverip.org/our-work/technical-architecture/" target="_blank">Trust Over IP</a>
                        <Paragraph>The TOIP technology architecture specification</Paragraph>
                    </DocumentacionCouples>
                    <DocumentacionCouples>
                        <a href="https://identity.foundation/education/" target="_blank">Decentralized Identity Foundation</a>
                        <Paragraph>Education Resources</Paragraph>
                    </DocumentacionCouples>
                    <DocumentacionCouples>
                        <a href="https://sovrin.org/library/" target="_blank">Sovrin Foundation</a>
                        <Paragraph>Library</Paragraph>
                    </DocumentacionCouples>
                </DocumentacionLinks>
                <Fade delay={500} triggerOnce>
                    <DocumentacionImage src={Documento} />
                </Fade>
            </DocumentacionWrapper>
            <Footer />
        </HomeWrapper>
    )
};

export default Documentacion;
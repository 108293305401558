import React, {useContext} from "react";
import ParaQuien from "../../components/paraquien";
import { Text, LanguageContext } from '../../containers/Language';


const Empresas = () => {
    const { dictionary } = useContext(LanguageContext);
    return(
        <ParaQuien
            videoUrl="https://www.youtube.com/embed/e0VR5qleKi4"
            group=<Text tid={"empresasTab"}/>
            title=<Text tid={"empresasTitle"}/>
            textboxtitle=<Text tid={"empresasSubTitle"}/>
            textboxp1=<Text tid={"empresasP1"}/>
            textboxp2=<Text tid={"empresasP2"}/>
            textboxp3=<Text tid={"empresasP3"}/>
            textboxp4=<Text tid={"empresasP4"}/>
        />
    )
};

export default Empresas;
import styled from 'styled-components';

export const HomeWrapper = styled.section`
    margin: 0 auto;
`

export const NavbarWrapper = styled.div`
  padding: 44px 55px 55px;

  @media screen and (max-width: 992px){
        padding: 22px 10px 10px;
  }
`

export const MainSection = styled.section`
    display: flex;    
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    padding: 90px;

    @media (max-width: 992px) {
      padding: 45px;
    }  

    @media (max-width: 1100px) {
        flex-direction: column;   
    } 
`

export const ComunidadBannerWrap = styled.img`
    max-width: 800px;
    margin-top: 53px;     

    @media (max-width: 1440px) {
      max-width: 600px;
    }   

    @media (max-width: 700px) {
      max-width: 360px;
      margin-top: 30px;
    }  
`

export const ComunidadTitle = styled.h2`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 74.88px;
  color: #008AA1;

  @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 55px;
  }   

  @media (max-width: 475px) {
      font-size: 32px;
      line-height: 36.7px;
  }  
`;

export const TabTitle = styled.h3`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 114.68%;
  color: #008AA1;
  text-transform: uppercase;
`

export const LeftContentWrapper = styled.div`
  display: flex;    
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
  width: 100%;
  max-width: 900px;

  @media (max-width: 1440px) {
      max-width: 900px;
    }  
`;

export const ComunidadParagraph = styled.p`
  font-family: 'ManropeRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #474747;
`;

export const ComunidadIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`

export const ContactWrapper = styled.img`      
  max-width: 185px;
  height: 25px;       
  padding: 5px;
`
import React from "react";
import Logo from "../../pages/assets/images/logo.svg";
import {
  LogoWrapper,
  FooterFirstGroup,
  FooterSecondGroup,
  FooterWrapper,
  LogoBackground,
  ContactWrapper,
  LogoLink,
  ContactButtonsWrapper
} from "./style";
import {
  CopyrightText
} from "../../pages/style/globalstyle";
import Youtube from "../../pages/assets/images/youtube.svg";
import Linkedin from "../../pages/assets/images/linkedin.png";
import Discord from "../../pages/assets/images/discord.png";
import Instagram from "../../pages/assets/images/instagram.svg";
import Twitter from "../../pages/assets/images/twitter.svg";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterFirstGroup>
        <LogoBackground>
          <LogoLink href="/">
            <LogoWrapper src={Logo} />
          </LogoLink>
        </LogoBackground>
      </FooterFirstGroup>
      <FooterSecondGroup>
        <ContactButtonsWrapper>
          <a href="https://www.twitter.com/quark_id" target="_blank"><ContactWrapper src={Twitter} /></a>
          <a href="https://www.linkedin.com/company/quarkid/" target="_blank"><ContactWrapper src={Linkedin} /></a>
          <a href="https://www.youtube.com/@quarkid/" target="_blank"><ContactWrapper src={Youtube} /></a>
          {/*
          <ContactWrapper src={Linkedin} />
          <ContactWrapper src={Discord} />  
        */}
        </ContactButtonsWrapper>
        <ContactButtonsWrapper>
        <CopyrightText>COPYRIGHT {new Date().getFullYear()} QUARKID</CopyrightText>
        </ContactButtonsWrapper>
      </FooterSecondGroup>
    </FooterWrapper>
  );
};

export default Footer;

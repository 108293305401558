import React, {useContext} from "react";
import ParaQuien from "../../components/paraquien";
import { Text, LanguageContext } from '../../containers/Language';

const Personas = () => {
    const { dictionary } = useContext(LanguageContext);
    return(
        <ParaQuien 
            videoUrl="https://www.youtube.com/embed/a0o5HKyYMig"
            group=<Text tid={"personasTab"}/>
            title=<Text tid={"personasTitle"}/>
            textboxtitle=<Text tid={"personasSubTitle"}/>
            textboxp1=<Text tid={"personasP1"}/>
            textboxp2=<Text tid={"personasP2"}/>
            textboxp3=<Text tid={"personasP3"}/>
            textboxp4=<Text tid={"personasP4"}/>
        />
    )
};

export default Personas;
import React, { useContext } from "react";
import ParaQuien from "../../components/paraquien";
import { Text, LanguageContext } from '../../containers/Language';
import ArrowSvg from '../assets/images/arrowsvg.png';
import { DocumentacionButton } from '../style/globalstyle';


const Gobiernos = () => {
    const { dictionary } = useContext(LanguageContext);
    return (
        <>
            <ParaQuien
                videoUrl="https://www.youtube.com/embed/x0mAekbGvXQ"
                group=<Text tid={"gobiernosTab"} />
                title=<Text tid={"gobiernosTitle"} />
                textboxtitle=<Text tid={"gobiernosSubTitle"} />
                textboxp1=<Text tid={"gobiernosP1"} />
                textboxp2=<Text tid={"gobiernosP2"} />
                textboxp3=<Text tid={"gobiernosP3"} />
                textboxp4=<Text tid={"gobiernosP4"} />
                textboxtitle2=<Text tid={"gobiernosSubTitle2"} />
                textboxp5=<Text tid={"gobiernosP5"} />
                button=<Text tid={"gobiernosButton"} />
                link=<Text tid={"https://buenosaires.gob.ar/quarkid"} />
            />

        </>
    )
};

export default Gobiernos;
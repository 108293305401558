import styled from "styled-components";
import "./globalfonts.scss";

export const Caption = styled.h1`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 200px;
  line-height: 93.6%;
  color: #ffffff;
  
  @media screen and (max-width: 992px){
    font-size: 80px;
  }
`;

export const CaptionNumbersMap = styled.h1`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 100px;
  line-height: 116px;
  color: #ffffff;

  
  @media screen and (max-width: 992px){
    font-size: 80px;
  }

`;


export const CaptionTextMap = styled.h1`
font-family: 'ManropeRegular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #FFFFFF;
`;

export const GobiernoButton = styled.button`
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px 35px;
    gap: 8px;
    background: #2D0060;
    border-radius: 32px;
    border: none;
    cursor: pointer;

    a {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        font-family: 'ManropeBody2';
    }

    @media screen and (max-width: 992px){
        a {
            font-size: 14px;
        }
    }
`

export const Title = styled.h2`
  font-family: "ManropeBody2";
  font-style: normal;
  font-weight: 400;
  font-size: 51px;
  line-height: 114.68%;
  color: #000000;

  span {
    font-family: "ManropeBold";
    font-weight: 800;
    background: -webkit-linear-gradient(
      90deg,
      rgba(45, 0, 96, 1) 0%,
      rgba(0, 138, 161, 1) 86%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 992px){
    font-size: 40px;
  }
`;

export const Title2 = styled.h2`
  font-family: "ManropeBody2";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 93.6%;
  color: #008aa1;

  @media screen and (max-width:1440px){
      font-size: 40px;
    }
`;

export const Paragraph = styled.p`
  font-family: "ManropeBody1";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: #1f1f1f;

  @media screen and (max-width: 992px){
    font-size: 18px;
    line-height: 19px;
  }
`;

export const ParagraphFramework = styled.p`
  font-family: "ManropeBody1";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  color: #e0b81f;

  @media screen and (max-width: 992px){
    font-size: 18px;
    line-height: 19px;
  }
`;

export const Label = styled(Paragraph)`
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #000000;

  @media screen and (max-width: 475px){
    line-height: 19px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  font-family: "ManropeBody1";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #1f1f1f;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 24px;
  gap: 8px;
  background: #008aa1;
  border-radius: 24px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 475px){
    padding: 4px 12px;

    a {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const ButtonText = styled(Link)`
  color: #ffffff;
`;

export const CopyrightText = styled.p`
  font-family: "ManropeBody1";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 33px;
  color: #ffffff;

  @media screen and (max-width: 425px){
    font-size: 12px;
  }
`;

export const DemoButton = styled.button`
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 64px;
  gap: 8px;
  background: #FFFFFF;
  border-radius: 32px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 992px){
    margin-top: 50px;
  }
`;

export const DemoButtonText = styled(Link)`
  color: #2D0060;
`;
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import {
  Caption,
  Title,
  Button,
  ButtonText,
  DivWrapper,
  Paragraph,
  ParagraphFramework,
  GlobalWrapper,
  Title2,
  Label,
  DemoButton,
  DemoButtonText,
  CaptionNumbersMap,
  CaptionTextMap,
  Link
} from "../style/globalstyle";
import {
  CaptionWrapper,
  DescentralizadoDiv,
  HomeBanner,
  HomeWrapper,
  ImageBanner,
  BgSideOne,
  BgSideTwo,
  UsuariosYCiudadanos,
  UsuariosYCiudadanosText,
  UsuariosYCiudadanosImage,
  BgSideThree,
  ConfiguraUtilizacion,
  ConfiguraUtilizacionText,
  ConfiguraCard,
  ConfiguraCardText,
  ConfiguraCardTextWrapper,
  CardImage,
  CredencialesWrapper,
  CredencialesText,
  CredentialsGrid,
  CredentialsImageWrapper,
  Multichain,
  MultichainText,
  MultichainImageWrapper,
  BgSideFour,
  PartnersDiv,
  PartnersDivBA,
  PartnersLogos,
  CallToActionWrapper,
  MapWrapper,
  MapStats,
  Map,
  HorizontalLine,
  ConfiguraCardsWrapper,
  PartnersDivMobile,
  PartnersLogosMobile,
  StoreWrapper,
  ImageStore,
  DevelopedByLogo
} from "./style";
import Logo from "../assets/images/logo.svg";
import MapImage from "../assets/images/map.png";
import AppleES from "../assets/images/AppleES.svg";
import AppleEN from "../assets/images/AppleEN.svg";
import GoogleES from "../assets/images/GoogleES.svg";
import GoogleEN from "../assets/images/GoogleEN.svg";
import BannerES from "../assets/images/bgphonees.png";
import BannerMobileES from "../assets/images/bgphonemobileEN.png";
import BannerMobileEN from "../assets/images/bgphonemobileES.png";
import BannerEN from "../assets/images/bgphoneen.png";
import UsuariosImage from "../assets/images/usuariosimage.png";
import UsuariosImageEN from "../assets/images/usuariosimageEN.png";
import UsuariosImageMobile from "../assets/images/usuariosimagemobile.png";
import UsuariosImageMobileEN from "../assets/images/usuariosimagemobileEN.png";
import BgSideOneImage from "../assets/images/bgsideone.png";
import BgSideTwoImage from "../assets/images/bgsidetwo.png";
import BgSideThreeImage from "../assets/images/bgsidethree.png";
import BgSideFourImage from "../assets/images/bgsidefour.png";
import CardImageOne from "../assets/images/cardimageone.png";
import CardImageTwo from "../assets/images/cardimagetwo.png";
import CardImageThree from "../assets/images/cardimagethree.png";
import CredentialImage from "../assets/images/credentialsimage.png";
import MultichainImage from "../assets/images/multichainimage.png";
import Extrimian from "../assets/images/extrimian.png";
import Oscity from "../assets/images/oscity.png";
import Iovlabs from "../assets/images/iovlabs.png";
import zksync from "../assets/images/zksync.png";
import Beon from "../assets/images/beon.png";
import Xcapit from "../assets/images/xcapit.png";
import Vivamos from "../assets/images/vivamos.png";
import Lujan from "../assets/images/lujandecuyo.png";
import BA from "../assets/images/buenosairesciudad.png";
import { Slide, Fade } from "react-awesome-reveal";
import { Text, LanguageContext } from "../../containers/Language";

const Home = () => {
  const useWindowDimensions = () => {
    const hasWindow = typeof window !== "undefined";
    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  };

  const { height, width } = useWindowDimensions();
  const breakpoint = 1280;
  const breakpointBanner = 390;

  const { userLanguage, dictionary } = useContext(LanguageContext);
  return (
    <>
      <Navbar logo={Logo} />
      <HomeWrapper>
        <Fade>
          <HomeBanner>
            {width <= breakpointBanner ? (
              <ImageBanner src={userLanguage === "es" ? BannerMobileES : BannerMobileEN} />
            ) : (
              <ImageBanner src={userLanguage === "es" ? BannerES : BannerEN} />
            )}
            <CaptionWrapper>
              {width <= breakpointBanner ? (
                null
              ) : (
                <Paragraph>
                  <Text tid={"bannerTab"} />
                </Paragraph>
              )}
              <Slide delay={500}>
                <Caption><Text tid={"bannerTitle"} /></Caption>
              </Slide>
            </CaptionWrapper>
          </HomeBanner>
        </Fade>
        <DescentralizadoDiv>
          <Fade delay={200}>
            <BgSideOne src={BgSideOneImage} />
          </Fade>
          <Fade delay={600}>
            {userLanguage === "es" ? (
              <Title>
                Es <span>descentralizado</span>, público, no permisionado,
                abierto, extensible y <span>multichain</span>, capaz de
                interoperar con otros protocolos similares.
              </Title>
            ) : (
              <Title>
                It’s <span>decentralized</span>, public, permissionless, open,
                extensible and <span>multichain</span>, capable of
                interoperating with other similar protocols.
              </Title>
            )}
          </Fade>
          <Fade delay={200}>
            <BgSideTwo src={BgSideTwoImage} />
          </Fade>
        </DescentralizadoDiv>
        <UsuariosYCiudadanos>
          <UsuariosYCiudadanosText>
            <Title>
              <Text tid={"section2Title"} />
            </Title>
            <Paragraph>
              <Text tid={"section2Text"} />
            </Paragraph>
          </UsuariosYCiudadanosText>
          <Fade delay={600}>
            {width <= breakpoint ? (
              <UsuariosYCiudadanosImage src={userLanguage === "es" ? UsuariosImageMobile : UsuariosImageMobileEN} />
            ) : (
              <UsuariosYCiudadanosImage src={userLanguage === "es" ? UsuariosImage : UsuariosImageEN} />
            )}
          </Fade>
          <Fade delay={200}>
            <BgSideThree src={BgSideThreeImage} />
          </Fade>
        </UsuariosYCiudadanos>
      </HomeWrapper>
      <ConfiguraUtilizacion>
        <ConfiguraUtilizacionText>
          <Label>
            <Text tid={"section3Tab"} />
          </Label>
          <Title>
            <Text tid={"section3Title"} />
          </Title>
          <Paragraph className="sub">
            <Text tid={"section3Text"} />
          </Paragraph>
        </ConfiguraUtilizacionText>
        <ConfiguraCardsWrapper>
          <Slide delay={200} duration={1500} direction="left">
            <ConfiguraCard>
              <ConfiguraCardTextWrapper>
                <Paragraph>01.</Paragraph>
                <ConfiguraCardText>
                  <Title>
                    <Text tid={"section3ListTitle1"} />
                  </Title>
                  <Paragraph>
                    <Text tid={"section3ListText1"} />
                  </Paragraph>
                </ConfiguraCardText>
              </ConfiguraCardTextWrapper>
              <CardImage src={CardImageOne} />
            </ConfiguraCard>
          </Slide>
          <Slide delay={200} duration={1500} direction="right">
            <ConfiguraCard>
              <ConfiguraCardTextWrapper>
                <Paragraph>02.</Paragraph>
                <ConfiguraCardText>
                  <Title>
                    <Text tid={"section3ListTitle2"} />
                  </Title>
                  <Paragraph>
                    <Text tid={"section3ListText2"} />
                  </Paragraph>
                </ConfiguraCardText>
              </ConfiguraCardTextWrapper>
              <CardImage id="womanImage" src={CardImageTwo} />
            </ConfiguraCard>
          </Slide>
          <Slide delay={200} duration={1500} direction="left">
            <ConfiguraCard>
              <ConfiguraCardTextWrapper>
                <Paragraph>03.</Paragraph>
                <ConfiguraCardText>
                  <Title>
                    <Text tid={"section3ListTitle3"} />
                  </Title>
                  <Paragraph>
                    <Text tid={"section3ListText3"} />
                  </Paragraph>
                </ConfiguraCardText>
              </ConfiguraCardTextWrapper>
              <CardImage src={CardImageThree} />
            </ConfiguraCard>
          </Slide>
        </ConfiguraCardsWrapper>
      </ConfiguraUtilizacion>
      <HomeWrapper>
        <CredencialesWrapper>
          <CredencialesText>
            <Label>
              <Text tid={"section4Tab"} />
            </Label>
            <Title>
              <Text tid={"section4Title"} />
            </Title>
            <Paragraph className="sub">
              <Text tid={"section4Text"} />
            </Paragraph>
          </CredencialesText>
          <CredentialsGrid>
            <Fade delay={500}>
              <ConfiguraCardText>
                <Title>
                  <Text tid={"section5Column1Title"} />
                </Title>
                <Paragraph>
                  <Text tid={"section5Column1Text"} />
                </Paragraph>
              </ConfiguraCardText>
            </Fade>
            <Fade delay={200}>
              <ConfiguraCardText>
                <Title>
                  <Text tid={"section5Column2Title"} />
                </Title>
                <Paragraph>
                  <Text tid={"section5Column2Text"} />
                </Paragraph>
              </ConfiguraCardText>
            </Fade>
            <Fade delay={500}>
              <ConfiguraCardText>
                <Title>
                  <Text tid={"section5Column3Title"} />
                </Title>
                <Paragraph>
                  <Text tid={"section5Column3Text"} />
                </Paragraph>
              </ConfiguraCardText>
            </Fade>
          </CredentialsGrid>
          <CredentialsImageWrapper>
            <Fade delay={500}>
              <img src={CredentialImage} />
            </Fade>
          </CredentialsImageWrapper>
        </CredencialesWrapper>
        <Multichain>
          <Fade delay={400}>
            <BgSideFour src={BgSideFourImage} />
          </Fade>
          <MultichainText>
            <Slide delay={300}>
              <Caption>
                <Text tid={"section6Title"} />
              </Caption>
            </Slide>
            <Paragraph>
              <Text tid={"section6Text"} />
            </Paragraph>
          </MultichainText>
          <MultichainImageWrapper>
            <Slide direction="right">
              <img src={MultichainImage} />
            </Slide>
          </MultichainImageWrapper>
        </Multichain>
        <PartnersDivBA>
          <ParagraphFramework>
            <Text tid={"section6BISText"} />
          </ParagraphFramework>
        </PartnersDivBA>
        <PartnersDivBA>
          <Paragraph>
            <Text tid={"section7BISTitle"} />
          </Paragraph>
          <DevelopedByLogo>
            <img src={BA} />
          </DevelopedByLogo>
        </PartnersDivBA>
        <PartnersDiv>
          <Paragraph>
            <Text tid={"section7Title"} />
          </Paragraph>
          <PartnersLogos>
            <img src={Extrimian} />
            <img src={Oscity} />
            <img src={Iovlabs} />
            <img src={zksync} />
            <img src={Beon} />
          </PartnersLogos>
          <PartnersLogos>
            <img src={Xcapit} />
            <img src={Vivamos} />
            <img id="lujanDesktop" src={Lujan} />

          </PartnersLogos>
        </PartnersDiv>
        <PartnersDivMobile>
          <Paragraph>
            <Text tid={"section7Title"} />
          </Paragraph>
          <PartnersLogosMobile>
            <img src={Extrimian} />
            <img src={Oscity} />
          </PartnersLogosMobile>
          <PartnersLogosMobile>
            <img src={BA} />
            <img src={zksync} />
          </PartnersLogosMobile>
          <PartnersLogosMobile>
            <img src={Beon} />
            <img src={Vivamos} />
          </PartnersLogosMobile>
          <PartnersLogosMobile>
            <img className="luján" src={Lujan} />
            <img className="xcapit" src={Xcapit} />
            <img className="iovlabs" src={Iovlabs} />
          </PartnersLogosMobile>
        </PartnersDivMobile>
      </HomeWrapper>
      <MapWrapper>
        <MapStats>
          <Slide>
            <CaptionNumbersMap>5</CaptionNumbersMap>
            <CaptionTextMap>
              <Text tid={"mapaPaises"} />
            </CaptionTextMap>
            <HorizontalLine></HorizontalLine>
          </Slide>
          <Slide delay={100}>
            <CaptionNumbersMap>12</CaptionNumbersMap>
            <CaptionTextMap>
              <Text tid={"mapaCiudades"} />
            </CaptionTextMap>
            <HorizontalLine></HorizontalLine>
          </Slide>
          <Slide delay={200}>
            <CaptionNumbersMap>2500+</CaptionNumbersMap>
            <CaptionTextMap>
              <Text tid={"mapaUsuarios"} />
            </CaptionTextMap>
          </Slide>
        </MapStats>
        <Fade delay={500}>
          <Map src={MapImage} />
        </Fade>
      </MapWrapper>
      <CallToActionWrapper>
        <Fade delay={200}>
          <Caption>
            <Text tid={"section8Title"} />
          </Caption>
        </Fade>
        <Fade delay={200}>
          {/* <DemoButton delay={800}>
            <DemoButtonText>
              <Text tid={"section8Button"} />
            </DemoButtonText>
          </DemoButton> */}
          <StoreWrapper>
            {<>
              <Link href="https://apps.apple.com/ar/app/quarkid-wallet/id6450680088" target="_blank"><ImageStore src={userLanguage === "es" ? AppleES : AppleEN} /></Link>
              <Link href="https://play.google.com/store/apps/details?id=com.quarkid" target="_blank"><ImageStore src={userLanguage === "es" ? GoogleES : GoogleEN} height={userLanguage === "es" ? "70px" : null} /></Link>
            </>
            }
          </StoreWrapper>
        </Fade>
      </CallToActionWrapper>
      <Footer />
    </>
  );
};

export default Home;

import styled from 'styled-components';

export const HomeWrapper = styled.section`
    width: 100%;
    margin: 0 auto;    
`

export const NavbarWrapper = styled.div`
    padding: 44px 55px 55px;

    @media screen and (max-width: 475px){
      padding: 22px 25px 25px;
    }
`

export const MainSection = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: center;
    padding: 90px;
    flex-wrap: wrap;   

    @media screen and (max-width: 992px){
      padding: 60px;
    }
    
    @media screen and (max-width: 475px){
      padding: 25px;
    }
`

export const ParaquienTitle = styled.h2`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 114.68%;
  color: #2D0060;
  text-align: center;
  max-width: 1440px;
  margin-bottom: 110px;
  
    @media screen and (max-width: 1440px){
        font-size: 60px;
        max-width: 1100px;
    }

    @media screen and (max-width: 475px){
        margin-bottom: 64px;
        font-size: 32px;
        max-width: 300px;
    }
`

export const TabTitle = styled.h3`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 5;
  color: #1F1F1F;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;

  span {
    font-weight: 800;
  }
`

export const FrameWrapper = styled.div` 
    width: 100%;
    margin-bottom: 110px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 475px){
      margin-bottom: 50px;
    }
`
export const TextBox = styled.div`    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1100px;
    row-gap: 32px;

    h2 {
      color: #c95629;
      line-height: 25px;
      font-size: 23px !important;
    }

    @media (max-width: 900px) {
        width: 100%; 
    }        

    p {
      line-height: 25px;
    }

    @media screen and (max-width: 475px){
      row-gap: 24px;

      h2 {
        font-size: 20px;
        font-family: 400;
      }

      p {
        font-size: 14px;
      }
    }
`

 export const ParaquienParagraph = styled.p` 
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  color: #1f1f1f;
 `



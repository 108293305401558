import React from "react";
import {
    Frame
} from "./style";

const Video = (props) => {
    const embedUrl = props.url;
    return(
        <Frame
            src={embedUrl}
            allowFullScreenframeBorder="0"
            className="iframe"
            allow="autoplay"
            title="example"            
        />
    )

}

export default Video;
import styled from 'styled-components';

export const HomeWrapper = styled.section`
    padding: 44px 55px 55px;

    @media screen and (max-width: 992px){
        padding: 0px 20px 20px;
    }
`

export const HomeBanner = styled.div`
    width: 100%;
    position: relative;
    margin-top: 30px;

    @media screen and (max-width: 992px){
        margin-top: 10px;
        margin-bottom: 100px;
    }
`

export const ImageBanner = styled.img`
    width: 100%;
`
export const ImageStore = styled.img`
    height: 120px;
    @media screen and (max-width: 891px){  
        height: 60px;
    }
   
`

export const CaptionWrapper = styled.div`
    position: absolute;
    width: 100%;
    max-width: 790px;
    left: 3%;
    top: 15%;

    p {
        font-size: 20px;
        letter-spacing: 0.075em;
        color: white;
        position: absolute;
        top: -25%;
    }

    @media screen and (max-width:1800px){
        max-width: 500px;
        top: 26%;
        h1 { 
            font-size: 120px;
        }

        p {
            top: -55%;
        }
    }

    @media screen and (max-width:1280px){
        max-width: 300px;
        top: 25%;
        h1 { 
            font-size: 100px;
        }
    }

    @media screen and (max-width: 992px){
        max-width: 300px;
        top: 20%;
        h1 { 
            font-size: 60px;
        }

        p {
            top: -30%;
            font-size: 14px;
        }
    }

    @media screen and (max-width: 475px){
        max-width: 150px;
        top: 20%;

        h1 { 
            font-size: 40px;
        }

        p {
            top: -40%;
            font-size: 12px;
            line-height: 13px;
        }
    }

    @media screen and (max-width: 390px){
        max-width: 100px;
        top: 10%;

        h1 { 
            font-size: 60px;
        }

        p {
            top: -40%;
            font-size: 10px;
        }
    }

    @media screen and (max-width: 330px){
        max-width: 100px;

        h1 { 
            font-size: 40px;
        }

        p {
            top: -40%;
            font-size: 10px;
        }
    }
`

export const DescentralizadoDiv = styled.div`
    width: 100%;
    height: auto;
    margin-top: 200px;
    
    h2 {
        padding: 0px 350px;
        font-weight: 600;
    }

    @media screen and (max-width:1440px){
        h2 {
            padding: 0px 100px 0px 130px;
        }
    }

    @media screen and (max-width: 992px){
        h2 {
            padding: 0px 80px 0px 80px;
        }
    }

    @media screen and (max-width: 475px){
        margin-top: 50px;
        h2 { 
            font-size: 20px;
            line-height: 24px;
            padding: 0px 40px 0px 40px;
        }
    }
`

export const BgSideOne = styled.img`
    position: absolute;
    left: 0;
    margin-top: -450px;

    @media screen and (max-width: 1440px){
        margin-top: -400px;
    }

    @media screen and (max-width: 992px){
        max-width: 350px;
        margin-top: -300px;
    }

    @media screen and (max-width: 475px){
        max-width: 150px;
        margin-top: -130px;
    }

    @media screen and (max-width: 390px){
        margin-top: -110px;
    }
`

export const BgSideTwo = styled.img`
    position: absolute;
    right: 0;

    @media screen and (max-width: 1440px){
        padding: 180px 0px 0px 80px;
    }

    @media screen and (max-width: 1440px){
        margin-top: -200px;
    }

    @media screen and (max-width: 992px){
        max-width: 250px;
        margin-top: -250px;
    }

    @media screen and (max-width: 475px){
        max-width: 150px;
        margin-top: -180px;
    }
`

export const UsuariosYCiudadanos = styled.div`
    margin-top: 350px;
    height: auto;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1280px){
        flex-direction: column;
        row-gap: 30px;
        margin-top: 100px;
    }

    @media screen and (max-width: 992px){
        margin-top: -100px;
    }

    @media screen and (max-width: 475px){
        margin-top: -280px;
    }
`

export const UsuariosYCiudadanosImage = styled.img`
    width: 100%;
`

export const UsuariosYCiudadanosText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 32px;
    max-width: 800px;
    position: absolute;
    margin-top: 450px;

    h2 {
        font-family: 'ManropeBody2';
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 93.6%;
        color: #008AA1;
    }

    @media screen and (max-width: 1600px){
        margin-top: 350px;
    }

    @media screen and (max-width: 1440px){
        margin-top: 400px;
        h2 {
            font-size: 60px;
        }
    }

    @media screen and (max-width: 1280px){
        position: relative;
    }

    
    @media screen and (max-width: 992px){
        h2 {
            font-size: 40px;
        }
    }

    @media screen and (max-width: 475px){
        row-gap: 10px;
        max-width: 320px;
        h2 { 
            font-size: 32px;
            line-height: 36.7px;
        }

        p {
            font-size: 14px;
        }
    }
`

export const BgSideThree = styled.img`
    position: absolute;
    right: 0;
    z-index: 0;

    @media screen and (max-width: 992px){
        max-width: 150px;
    }

    @media screen and (max-width: 475px){
        max-width: 80px;
    }
`

export const ConfiguraUtilizacion = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    margin-top: 220px;
    padding: 44px 55px 55px;
    background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #EEEEEE 8.33%, #EEEEEE 91.44%, rgba(238, 238, 238, 0) 100%);

    @media screen and (max-width: 992px){
        padding: 44px 20px 0px;
    }

    @media screen and (max-width: 475px){
        margin-top: 40px;
    }
`

export const ConfiguraUtilizacionText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1150px;

    h2 {
        font-family: 'ManropeBody1';
        font-style: normal;
        font-weight: 600;
        font-size: 80px;
        line-height: 93.6%;
        color: #2D0060;
        margin-top: 8px;
        margin-bottom: 32px;

        @media screen and (max-width:1440px){
            max-width: 900px;
            font-size: 60px;
        }

        @media screen and (max-width: 992px){
            font-size: 40px;
        }
        
        @media screen and (max-width: 475px){
            font-size: 32px;
            line-height: 36.7px;
        }   
    }

    @media screen and (max-width: 475px){
        margin-bottom: 120px;
        .sub {
            font-size: 18px;
            line-height: 25px;
        }

        p {
            font-size: 14px;
        }
    }
`

export const ConfiguraCardsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1500px;

    @media screen and (max-width:1440px){
        max-width: 1100px;
    }

    @media screen and (max-width: 475px){
        margin-top: -100px;
    }
`

export const ConfiguraCard = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid grey;
    padding: 50px 0px;
    position: relative;

    @media screen and (max-width: 992px){
        height: 550px;
    }
    
    @media screen and (max-width: 475px){
        height: 400px;

        img {
            max-width: 250px;
        }

        #womanImage {
            max-width: 220px;
        }
    }

    @media screen and (max-width: 360px){
        height: 440px;
    }

    @media screen and (max-width: 330px){
        height: 480px;
    }
`

export const ConfiguraCardTextWrapper = styled.div`
    display: flex;
    column-gap: 32px;
    align-items: flex-start;
`

export const ConfiguraCardText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 32px;
    max-width: 520px;

    h2 {
        font-family: 'ManropeBody2';
        font-weight: 400;
        background: -webkit-linear-gradient(90deg, rgba(45,0,96,1) 0%, rgba(0,138,161,1) 86%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1.3;

        @media screen and (max-width: 475px){
            font-size: 28px;
        }
    }

    p {
        @media screen and (max-width: 475px){
            font-size: 14px;
        }
    }

    @media screen and (max-width: 475px){
        row-gap: 16px;
    }
`

export const CardImage = styled.img`
    width: 100%;
    max-width: 430px;
    position: absolute;
    right: 0;
    bottom: 0;
`

export const CredencialesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    margin-top: 100px;
`

export const CredencialesText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 8px;
    max-width: 1450px;

    h2 {
        font-family: 'ManropeBody1';
        font-style: normal;
        font-weight: 600;
        font-size: 80px;
        line-height: 93.6%;
        color: #2D0060;
        margin-top: 8px;
        margin-bottom: 32px;

        @media screen and (max-width:1440px){
            max-width: 1100px;
            font-size: 60px;
        }

        @media screen and (max-width: 992px){
            font-size: 40px;
        }

        @media screen and (max-width: 475px){
            font-size: 32px;
            line-height: 36.7px;
        }
    }

    @media screen and (max-width: 475px){
        .sub {
            font-size: 18px;
            line-height: 25px;
        }

        p {
            font-size: 14px;
        }
    }
`

export const CredentialsGrid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding: 90px 120px;
    column-gap: 250px;

    p {
        font-size: 16px;
        line-height: 22px;
    }

    @media screen and (max-width: 1440px){
        column-gap: 120px;
        padding: 90px 80px;
    }

    @media screen and (max-width: 1280px){
        column-gap: 80px;
        padding: 50px 60px;
    }

    @media screen and (max-width: 992px){
        flex-direction: column;
        align-items: flex-start;
        row-gap: 40px;
        padding: 25px 30px;
    }
`

export const CredentialsImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        max-width: 1100px;
    }
`

export const Multichain = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #008AA1;
    border-radius: 16px;
    position: relative;
    margin-top: 80px;
`

export const MultichainText = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 75px 420px 0px 142px;

    p {
        color: #FFFFFF;
        font-weight: 400;
    }

    @media screen and (max-width: 1800px){
        padding: 57px 420px 0px 109px;

        h1 { 
            font-size: 150px;
        }
    }

    @media screen and (max-width: 992px){
        padding: 57px 210px 0px 60px;
        
        h1 { 
            font-size: 80px;
        }
    }

    @media screen and (max-width: 475px){
        padding: 57px 0px 0px 15px;
        h1 { 
            font-size: 50px;
        }
    }
`

export const MultichainImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 35px;

    img {
        width: 100%;
        max-width: 1070px;
    }
`

export const BgSideFour = styled.img`
    position: absolute;
    right: 0;
    max-width: 500px;

    @media screen and (max-width: 1440px){
        max-width: 400px;
    }

    @media screen and (max-width: 992px){
        max-width: 250px;
    }

    @media screen and (max-width: 475px){
        max-width: 150px;
    }
`

export const PartnersDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    margin-top: 80px;
    margin-bottom: 80px;

    p {        
        text-align: center;
    }

    @media screen and (max-width: 992px){
        display: none;
    }
`

export const PartnersDivBA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    margin-top: 40px;
    margin-bottom: 40px;

    p {        
        text-align: center;
    }

   
`

export const PartnersLogos = styled.div`
    display: flex;
    column-gap: 70px;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        width: 100%;
        max-width: 250px;
        cursor: pointer;
    }

    #lujanDesktop {
        max-width: 180px;
    }

    @media screen and (max-width: 1500px){
        column-gap: 30px;

        img {
            max-width: 160px;
        }

        #lujanDesktop {
            max-width: 130px;
        }
    }

    @media screen and (max-width:992px){
        img {
            max-width: 100px;
        }
    }
`

export const DevelopedByLogo = styled.div`
    display: flex;
    column-gap: 70px;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        width: 100%;
        max-width: 350px;
        cursor: pointer;
    }

    #lujanDesktop {
        max-width: 250px;
    }

    @media screen and (max-width: 1500px){
        column-gap: 30px;

        img {
            max-width: 300px;
        }

        #lujanDesktop {
            max-width: 130px;
        }
    }

    @media screen and (max-width:992px){
        img {
            max-width: 300px;
        }
    }
`

export const PartnersDivMobile = styled.div`
    display: none;

    p {
        text-transform: uppercase;
    }

    @media screen and (max-width: 992px){
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    }
`

export const PartnersLogosMobile = styled.div`
    display: flex;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        max-width: 120px;
        cursor: pointer;
    }

    .luján {
        max-width: 60px;
    }

    .xcapit {
        max-width: 90px;
    }

    .iovlabs {
        max-width: 80px;
    }
`

export const CallToActionWrapper = styled.div`
    display: flex;
    width: 100%;
    background: linear-gradient(180deg, #1F1F1F 24.57%, #2D0060 100%); 
    height: 750px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 130px;
    }

    @media screen and (max-width: 1440px){
        h1 {
            font-size: 100px;
        }
    }

    @media screen and (max-width: 1100px){
        height: 500px;
        h1 {
            font-size: 50px;
        }
    }

    @media screen and (max-width: 765px){
        height: 250px;

        h1 {
            font-size: 30px;
        }
    }
`

export const MapWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    background: #1F1F1F; 
    flex-direction: row;
    align-items: center;

    .p {
        color: #FFFF;
    }
    
    @media screen and (max-width: 992px){
        flex-direction: column-reverse;
    }
`

export const MapStats = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 180px 0px 0px 130px;

    @media screen and (max-width: 1440px){
        padding: 180px 0px 0px 80px;
    }

    @media screen and (max-width: 1280px){
        padding: 180px 0px 0px 10px;
    }

    @media screen and (max-width: 992px){
        padding: 60px 0px 0px 30px;
    }
`

export const Map = styled.img`
    position: absolute;
    right: 0;
    top: 100px;

    @media screen and (max-width: 1800px){
        max-width: 1100px;
    }

    @media screen and (max-width: 1440px){
        max-width: 800px;
        top: 200px;
    }

    @media screen and (max-width: 992px){
        max-width: 100%;
        position: relative;
        margin-top: 80px;
        top: 0;
    }
`

export const HorizontalLine = styled.div`
width: 201px;
height: 0px;
left: 45px;
top: 283px;
border: 1px solid #FFFFFF;
margin: 50px;


@media screen and (max-width: 992px){
    width: 100px;
}
`

export const StoreWrapper = styled.div`
display: flex;
width: 100%;
margin-top: 40px;
gap: 40px;
flex-direction: row;
justify-content: center;
align-items: center;

@media screen and (max-width: 891px){  
    flex-direction: column;
}
`
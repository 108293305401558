import styled from "styled-components";

export const ContactoWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;


export const WrapperSecciones = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: 1300px){
    flex-wrap: wrap;
  }
`;

export const NavbarWrapper = styled.div`
  padding: 44px 55px 55px;
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  background: linear-gradient(135deg, #2d0060 0%, #008aa1 100%);
  padding: 50px;
  gap: 20px;
  
  @media (max-width: 1300px){
    width: 100%;
  }

  @media screen and (max-width: 475px){
    padding: 20px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 20px;
`;

export const LogoWrapper = styled.img`
  padding: 20px 0px;
  width: 100%;
  max-width: 187px;

  @media screen and (max-width: 475px){
    max-width: 130px;
  }
`;

export const ComunidadParagraph = styled.p`
  font-family: "ManropeRegular";
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;

  @media screen and (max-width: 475px){
    font-size: 14px;
    line-height: 19.12px;
  }
`;

export const ComunidadIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

export const ComunidadBannerWrap = styled.img`
  max-width: 50%;
  margin-top: 53px;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export const ComunidadTitle = styled.h2`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 114.68%;
  color: #ffffff;

  @media screen and (max-width: 1600px){
    font-size: 60px;
  }
  
  @media screen and (max-width: 475px){
    font-size: 32px;
  }
`;

export const ContactoRedes = styled.h2`
  font-family: "ManropeRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;

  @media screen and (max-width: 475px){
    font-size: 20px;
  }
`;

export const ContactWrapper = styled.img`
  max-width: 185px;
  height: 25px;
  padding: 5px;
`;

export const DemoContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row-reverse;
`


export const SubmitContainer = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  justify-content: center;

  @media screen and (max-width: 475px){
    padding: 10px;
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 50px;

  @media screen and (max-width: 475px){
    padding: 10px;
  }
`

export const FormTitle = styled.h2`
  font-family: 'Manroperegular';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #2D0060;
`

export const Label = styled.label`
  font-family: 'Manroperegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #2D0060;
  display: block;
`;


export const Input = styled.input`
	padding: 0.5em;
  border-bottom: 1px solid black;	
	width: 100%;
	margin-bottom: 0.5em;
`;

export const SuccessMessage = styled.p`
  margin-top: 20px;
  font-family: 'Manroperegular';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 38px;
  color: #2D0060;
  display: block;
  background-color: #98FB98;
  border-radius: 30px;
  text-align: center;
`;


export const ContactNavWrapper = styled.div`
  display: flex;
`
import React from "react";
import Home from './pages/home/index';
import Personas from './pages/personas/index';
import Gobiernos from './pages/gobiernos/index';
import Empresas from './pages/empresas/index';
import Documentacion from './pages/documentacion/index';
import Contacto from './pages/contacto/index';
import Comunidad from './pages/comunidad/index';
import Roadmap from './pages/roadmap/index';
import { LanguageProvider } from "./containers/Language";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import '../src/pages/style/reset.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const basename = process.env.PUBLIC_URL;

function App() {

  document.body.addEventListener('touchmove', function (event) {
    event.preventDefault();
  });

  return (
    <LanguageProvider>
      <ToastContainer position="top-right" autoClose={3000} />
      <Router basename={basename}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/personas" element={<Personas />} />
          <Route path="/gobiernos" element={<Gobiernos />} />
          <Route path="/empresas" element={<Empresas />} />
          <Route path="/documentacion" element={<Documentacion />} />
          <Route path="/comunidad" element={<Comunidad />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/roadmap" element={<Roadmap />} />
        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;

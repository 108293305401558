import styled from "styled-components";

export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 277.32px;
    background: #2D0060;
    position: relative;
    @media screen and (max-width: 992px){
        flex-direction: column;
        height: 300px;
        padding: 80px 0px 80px 0px;
    }
`

export const FooterFirstGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    column-gap: 20px;
    padding-bottom: 20px;
    &:hover {
        cursor: pointer;
    }
`

export const FooterSecondGroup = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 50px;
    min-width: 300px;
    gap: 10px;
    &:hover {
        cursor: pointer;
    }
    @media screen and (max-width: 992px){
        margin-left: auto;
    }
`

export const ContactButtonsWrapper = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    &:hover {
        cursor: pointer;
    }
    `

export const LogoWrapper = styled.img`    
    @media screen and (max-width: 992px){
        max-width: 130px;
        height: 30px;
    }
`

export const LogoBackground = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 450px;
    height: 157px;
    background-color: #FFFFFF;
    border-radius: 0px 91px 91px 0px;      
    justify-content: center;  
    align-items: center;
    text-align: center;
    @media screen and (max-width: 992px){
        max-width: 300px;
        height: 90px;      
    }

    @media screen and (max-width: 475px){
        max-width: 200px;
        height: 90px;    
        
        img {
            margin-left: -30px;
        }
    }
`

export const ContactWrapper = styled.img` 
    height: 25px;       
    padding-left: 5px;
`

export const LogoLink = styled.a`
    width: 100%;
    text-decoration: none;
`
import React, { useState, useContext, useEffect } from "react";
import {
  Link,
  Button,
  ButtonText,
} from "../style/globalstyle";
import {
  ContactoWrapper,
  WrapperSecciones,
  LeftContent,
  RightContent,
  LogoWrapper,
  ComunidadParagraph,
  ComunidadIcons,
  ComunidadTitle,
  ContactWrapper,
  ContactoRedes,
  FormContainer,
  FormTitle,
  Label,
  Input,
  SubmitContainer,
  InformationContainer,
} from "./style";
import Logo from "../assets/images/whitelogo.png";
import Footer from "../../components/footer";
import Twitter from "../../pages/assets/images/twitter.png";
import Instagram from "../../pages/assets/images/instagram.png";
import Youtube from "../../pages/assets/images/youtube.png";
import useForm from "../../hooks/useForm"
import { LogoLink } from "../../components/navbar/style";
import { Text, LanguageContext } from '../../containers/Language';
import Navbar from "../../components/navbar/index";
import 'react-toastify/dist/ReactToastify.css';


const Contacto = () => {
  const { dictionary } = useContext(LanguageContext);
  const initialData = {
    nombre: '',
    correo: '',
    telefono: '',
    institucion: '',
    mensaje: ''
  }

  const { form, errors, loading, handleChange, handleSubmit } = useForm(initialData)

  const [mailSent, setMailSent] = useState(false)

  const useWindowDimensions = () => {
    const hasWindow = typeof window !== "undefined";
    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  };

  const MailText = ({ tid, correoTid }) => {
    const languageContext = useContext(LanguageContext);
    const textContext = {
      texto: languageContext.dictionary[tid],
      correo: languageContext.dictionary[correoTid]
    }
    return (
      <ComunidadParagraph>
        {textContext.texto}
        <a href={`mailto:${textContext.correo}`}>{textContext.correo}</a>
      </ComunidadParagraph>
    );
  };

  const { height, width } = useWindowDimensions();
  const breakpoint = 1280;
  const breakpointBanner = 992;

  return (
    <ContactoWrapper>
      <WrapperSecciones>
        <LeftContent>
          {width <= breakpointBanner ?
            <Navbar logo={Logo} svgcolor={'white'} selectcolor={'white'} burgerColor={'#2D0060'} burgerLinksColor={'white'} burgerLinesColor={'#2D0060'} />
            :
            <LogoLink href="/">
              <LogoWrapper src={Logo} />
            </LogoLink>
          }
          <ComunidadTitle><Text tid={"contactoTitle"} /></ComunidadTitle>
          <ContactoRedes><Text tid={"contactoLinks"} /></ContactoRedes>
          <ComunidadIcons>
            <Link href="https://twitter.com" target="_blank">
              <ContactWrapper src={Twitter} />
            </Link>
            <Link href="https://instagram.com" target="_blank">
              <ContactWrapper src={Instagram} />
            </Link>
            <Link href="https://youtube.com" target="_blank">
              <ContactWrapper src={Youtube} />
            </Link>
          </ComunidadIcons>
        </LeftContent>
        <RightContent>
        <InformationContainer>
            <ComunidadParagraph>
              <Text tid={"contactoP1"} />
            </ComunidadParagraph>
            <ComunidadParagraph>
              <MailText tid={"contactoP2"} correoTid={"contactoEmail"}/>
            </ComunidadParagraph>
          </InformationContainer>
        </RightContent>
      </WrapperSecciones>
      <Footer />
    </ContactoWrapper>
  );
};

export default Contacto;

import { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';


const useForm = (initialData, onValidate) => {
  const [form, setForm] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [errors, setErros] = useState({})

  const handleChange = (event) => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true);
    try {
      const response = await axios.post('https://formsubmit.co/ajax/soporte@quarkid.org', form);
      if (response.status === 200) {
        toast.success('Mail sent successfully');
        setForm(initialData)
        setLoading(false)
      } else {
        toast.error('Failed to send mail');
      }
    } catch (error) {
      console.error('Error sending mail:', error);
      toast.error('Error sending mail');
      setLoading(false)
    }
  }
  return { form, errors, loading, handleChange, handleSubmit }
}

export default useForm;

import React, { useContext } from 'react';
import { Text, LanguageContext } from '../../containers/Language';
import {
    BulletItem1,
    BulletText1,
    PhaseTitle1,
    PhaseSubtitle1
} from "./style";
import { all } from 'axios';
import { createGlobalStyle } from 'styled-components';

const BulletText = ({ tid }) => {
  const languageContext = useContext(LanguageContext);
  const text = languageContext.dictionary[tid];

  return <BulletText1>{text}</BulletText1>;
};

const PhaseTitle = ({ tid }) => {
    const languageContext = useContext(LanguageContext);
    const text = languageContext.dictionary[tid];
  
    return <PhaseTitle1>{text}</PhaseTitle1>;
  };

  const PhaseSubtitle = ({ tid }) => {
    const languageContext = useContext(LanguageContext);
    const text = languageContext.dictionary[tid];
  
    return <PhaseSubtitle1>{text}</PhaseSubtitle1>;
  };

  const BulletItem = ({ tid }) => {
    const languageContext = useContext(LanguageContext);
    const text = languageContext.dictionary[tid];
  
    return <BulletItem1>{text}</BulletItem1>;
  };

  const GlobalStyle = createGlobalStyle`
    body {
        background-color: black;
        color: white; /* Opcional, para asegurar que el texto sea visible */
    }
`;

  export { BulletText, PhaseTitle, PhaseSubtitle, BulletItem, GlobalStyle };
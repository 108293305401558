import React, { useContext } from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import Video from "../componentvideo";
import {
    Title,
    Paragraph
} from '../../../src/pages/style/globalstyle';
import {
    HomeWrapper,
    NavbarWrapper,
    MainSection,
    TextBox,
    ParaquienTitle,
    TabTitle,
    Frame,
    ParaquienParagraph,
    FrameWrapper
} from "./style";
import Logo from '../../pages/assets/images/logo.svg';
import { Text, LanguageContext } from '../../containers/Language';
import { GobiernoButton } from '../../pages/style/globalstyle';
import { ButtonText } from '../../pages/style/globalstyle';

const ParaQuien = (props) => {
    const { dictionary } = useContext(LanguageContext);
    return (
        <HomeWrapper>
            <Navbar logo={Logo} />
            <MainSection>
                <TabTitle><Text tid={"paraQuienTab"} /> <span>{props.group}</span></TabTitle>
                <ParaquienTitle>{props.title}</ParaquienTitle>
                <FrameWrapper>
                    <Video url={props.videoUrl} />
                </FrameWrapper>
                <TextBox>
                    <Title>
                        {props.textboxtitle}
                    </Title>
                    <ParaquienParagraph>
                        {props.textboxp1}
                    </ParaquienParagraph>
                    <ParaquienParagraph>
                        {props.textboxp2}
                    </ParaquienParagraph>
                    <ParaquienParagraph>
                        {props.textboxp3}
                    </ParaquienParagraph>
                    <ParaquienParagraph>
                        {props.textboxp4}
                    </ParaquienParagraph>
                    {/* Deuda tecnica, habria que mapear la data a los componentes para que sea dinamico, ver si pasan botones, etc. Refactor general. */}
                    <Title>
                        {props.textboxtitle2}
                    </Title>
                    <ParaquienParagraph>
                        {props.textboxp5}
                    </ParaquienParagraph>
                    {props.button ? <GobiernoButton>
                        <a href="https://buenosaires.gob.ar/quarkid" target="_blank"><Text tid={props.button} /></a>
                    </GobiernoButton> : null}

                </TextBox>
            </MainSection>
            <Footer />
        </HomeWrapper>
    )
};

export default ParaQuien;
import React, { useContext } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import {
    Paragraph,
    Link
} from '../style/globalstyle'
import {
    HomeWrapper,
    NavbarWrapper,
    MainSection,
    ComunidadBannerWrap,
    ComunidadTitle,
    TabTitle,
    LeftContentWrapper,
    ComunidadParagraph,
    ComunidadIcons,
    ContactWrapper
} from "./style";
import ComunidadBanner from "../assets/images/comunidad.png";
import Twitter from "../../pages/assets/images/twitter2.png";
import Instagram from "../../pages/assets/images/instagram2.png";
import Youtube from "../../pages/assets/images/youtube2.png";
import Linkedin from "../../pages/assets/images/linkedin2.png";
import Discord from "../../pages/assets/images/discord2.png";
import Logo from '../assets/images/logo.svg';
import { Slide, Fade } from "react-awesome-reveal";
import { Text, LanguageContext } from '../../containers/Language';

const Comunidad = () => {
    const { dictionary } = useContext(LanguageContext);
    return (
        <HomeWrapper>
            <Navbar logo={Logo} />
            <MainSection>
                <Slide direction="left">
                    <LeftContentWrapper>
                        <TabTitle><Text tid={"comunidadTab"} /></TabTitle>
                        <ComunidadTitle><Text tid={"comunidadTitle"} /></ComunidadTitle>
                        <ComunidadParagraph><Text tid={"comunidadText"} /></ComunidadParagraph>
                        <Paragraph><Text tid={"comunidadLinks"} /></Paragraph>
                        <ComunidadIcons>
                            <Link href="https://www.twitter.com/quark_id" target="_blank"><ContactWrapper src={Twitter} /></Link>
                            <Link href="https://www.linkedin.com/company/quarkid/" target="_blank"><ContactWrapper src={Linkedin} /></Link>
                            <Link href="https://www.youtube.com/@quarkid/videos" target="_blank"><ContactWrapper src={Youtube} /></Link>
                            {/*
                                <Link href="https://instagram.com" target="_blank"><ContactWrapper src={Linkedin} /></Link>
                                <Link href="https://instagram.com" target="_blank"><ContactWrapper src={Discord} /></Link>
                            */}
                        </ComunidadIcons>
                    </LeftContentWrapper>
                </Slide>
                <Slide direction="right">
                    <ComunidadBannerWrap src={ComunidadBanner} />
                </Slide>
            </MainSection>
            <Footer />
        </HomeWrapper>
    )
};

export default Comunidad;
import styled from "styled-components";

export const NavbarWrapper = styled.div`
    padding: 30px 80px 30px;
    position: sticky;
    z-index: 2;
    a {
        color: ${props => (props.documentation ? 'white' : '')};
      }    
    background-color: ${props => (props.documentation ? props.documentation : '')};

    @media screen and (max-width: 1440px){
        padding: 24px 50px 24px;
    }

    @media screen and (max-width: 992px){
        padding: 20px 20px 20px;
    }

    @media screen and (max-width: 475px){
        padding: 10px 10px 10px;
    }

    select {
        background: none;
        color: ${props => (props.selectcolor ? props.selectcolor : 'black')};
    }
`

export const GroupsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

export const NavbarFirstGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    column-gap: 20px;

    &:hover {
        cursor: pointer;
    }
`

export const NavbarSecondGroup = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    column-gap: 20px;

    &:hover {
        cursor: pointer;
    }
`

export const LogoWrapper = styled.img`
    width: 85%;
    /* max-width: 200px;
    height: 35px;

    @media screen and (max-width: 992px){
        max-width: 140px;
    }

    @media screen and (max-width: 475px){
        max-width: 140px;
        height: 30px;
    } */
`

export const LogoLink = styled.a`
    width: 100%;
    text-decoration: none;
`

export const MobileContainers = styled.div`
    width: 100%;
    display: flex;
    column-gap: 20px;
    align-items: center;

    @media screen and (max-width: 992px){
        display: none;
    }

    .btn-primary {
        background-color: white;
        color: ${props => (props.selectcolor ? props.selectcolor : 'black')};
        border: none;
        text-decoration: none;
        font-family: "ManropeBody1";
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #1f1f1f;
        background: none;

        &:focus {
            background: none;
            color: black !important;
        }
    }

    .dropdown-item {
        color: black !important;
    }
`

export const BurgerMenu = styled.div`
    display: none;

    @media screen and (max-width: 992px){
        display: block;
        height: 24px;
        width: 32px;
        top: 17px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

export const Lines = styled.div`
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #2D0060;
`
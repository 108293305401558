import styled from 'styled-components';

export const Frame = styled.iframe`
    width: 100%;  
    max-width: 1150px;
    height: 650px;     
    border: 0;  

    @media screen and (max-width: 475px){
        max-width: 400px;
        height: 250px;
        margin-bottom: 50px;
    }
`
import styled from "styled-components";

export const NavMobileWrapper = styled.div`
    display: none;
    flex-direction: column;
    background-color: ${props => (props.burgerColor ? props.burgerColor : '#2D0060')};
    border-radius: 16px;
    width: 98%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    margin-top: 500px; 
    position: absolute;
    z-index: 99;
`

export const CloseButton = styled.button`
    border: none;
    background: none;
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 10px;

    img {
        width: 18px;
        height: 18px;
    }
`

export const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 33px;
    padding: 30px 10px 61px 15px;

    a {
        width: 100%;
        text-decoration: none;
        color:  ${props => (props.burgerLinksColor? props.burgerLinksColor + ' ! important' : 'white')};
    }
    
    select {
        color:  ${props => (props.burgerLinksColor? props.burgerLinksColor : 'white')};
        max-width: 40px;
        font-family: 'ManropeBody1';

        option {
            color: black;

            &focus {
                color: black;
            }
        }
    }
`

export const BurgerMenu = styled.div`
    display: none;

    @media screen and (max-width: 992px){
        display: block;
        height: 24px;
        width: 32px;
        top: 17px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

export const Lines = styled.div`
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: ${props => (props.burgerLinesColor ? props.burgerLinesColor : '#2D0060')};
`
import React, { useContext, useState } from "react";
import { CloseButton, LinksWrapper, NavMobileWrapper, BurgerMenu, Lines } from "./style";
import CloseImage from '../../pages/assets/images/closevector.svg';
import { Link } from '../../pages/style/globalstyle';
import { LanguageContext } from "../../containers/Language";
import { languageOptions } from "../../languages";
import { Text } from '../../containers/Language';

const MobileMenu = (props) => {
  const [menuState, changeMenuState] = useState(false); 
  const { userLanguage, userLanguageChange, dictionary } = useContext(LanguageContext);
  // set selected language by calling context method
  const handleLanguageChange = (e) => userLanguageChange(e.target.value);

  document.addEventListener('touchmove', function(event) {
    changeMenuState(false);
  });

  document.addEventListener('scroll', function(event) {
    changeMenuState(false);
  });

  return (
    <>
      <BurgerMenu onClick={() => changeMenuState(true)}>
        <Lines burgerLinesColor={props.burgerLinesColor} className="lineone"></Lines>
        <Lines burgerLinesColor={props.burgerLinesColor} className="linetwo"></Lines>
        <Lines burgerLinesColor={props.burgerLinesColor} className="linethree"></Lines>
      </BurgerMenu>
      <NavMobileWrapper burgerColor={props.burgerColor} style={{display : menuState ? 'flex' : 'none'}}>
        <CloseButton onClick={() => {changeMenuState(props.menuState)}}>
          <img src={CloseImage} />
        </CloseButton>
        <LinksWrapper burgerLinksColor={props.burgerLinksColor}>
          <Link href="/">Home</Link>
          <Link href="/personas"><Text tid={"navbarPersonas"}/></Link>
          <Link href="/empresas"><Text tid={"navbarEmpresas"}/></Link>
          <Link href="/gobiernos"><Text tid={"navbarGobiernos"}/></Link>
          <Link href="/documentacion"><Text tid={"navbarDocumentacion"}/></Link>
          <Link href="/comunidad"><Text tid={"navbarComunidad"}/></Link>
          <Link href="/contacto"><Text tid={"navbarContacto"}/></Link>
          <Link href="/roadmap"><Text tid={"navbarRoadmap"}/></Link>
          <select onChange={handleLanguageChange} value={userLanguage}>
            {Object.entries(languageOptions).map(([id, name]) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </LinksWrapper>
      </NavMobileWrapper>
    </>
  );
};

export default MobileMenu;
